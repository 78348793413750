import { useState } from 'react';

import Loader from 'src/components/Loader';
import { CustomerData } from 'src/types/CustomerData';
import { USER_MODE_IDS } from 'src/constants';
import { FormState } from '../../types/Form';
import CurrentStage from '../CurrentStage';
import styles from './index.module.scss';
import { QuestionStage, QuestionStageValues } from '../../types/Question';
import { historicalJobsFilter } from '../../utils/historicalJobsFilter';
import { JobTable } from '../JobTable';
import { FnolTrackingRequestBody } from '../../api/fnolTracking';

interface Props {
    questionStages: QuestionStage[];
    initialStageHandle: string;
    endStageHandles: string[];
    formSubmit: (formState: Partial<FormState>) => void;
    isFormSubmitting: boolean;
    customerData: CustomerData;
    userMode: string;
    trackFnolData: (fnolTrackingRequestBody: FnolTrackingRequestBody) => void;
    formState: Partial<FormState>;
    setFormState: (formState: Partial<FormState>) => void;
}

const Form = ({
    questionStages,
    initialStageHandle,
    formSubmit,
    isFormSubmitting,
    endStageHandles,
    customerData,
    userMode,
    trackFnolData,
    formState,
    setFormState,
}: Props) => {
    const [currentStageHandle, setCurrentStageHandle] = useState<string>(initialStageHandle);
    const [stageHistory, setStageHistory] = useState<string[]>([initialStageHandle]);

    const currentStage = questionStages.find((stage) => stage.handle === currentStageHandle);

    if (!currentStage) {
        // TODO add better error handling
        throw new Error(`No current stage found, trying to find: ${currentStageHandle}`);
    }

    const onStageSubmit = async (newStageState: QuestionStageValues, nextStageHandle: string) => {
        const currentStageCalculatedValues = currentStage.onSubmit(newStageState, formState);

        const updatedFormState = {
            ...formState,
            ...currentStageCalculatedValues,
        };
        const updatedStageHJistory = [...stageHistory, nextStageHandle];

        setStageHistory(updatedStageHJistory);

        setFormState(updatedFormState);

        // Submit form if we are on any of the last stage
        if (endStageHandles.includes(nextStageHandle)) {
            await formSubmit(updatedFormState);
        }

        trackFnolData({
            contractId: customerData.contractId,
            formData: updatedFormState,
            stageHistory: updatedStageHJistory,
            lastStage: nextStageHandle,
            userMode: USER_MODE_IDS[userMode],
        });

        setCurrentStageHandle(nextStageHandle);
    };

    if (isFormSubmitting) {
        return <Loader />;
    }

    return (
        <>
            {customerData.jobs &&
                initialStageHandle === currentStageHandle &&
                customerData.jobs.filter(historicalJobsFilter).length > 0 &&
                userMode === 'customer' && (
                    <JobTable jobs={customerData.jobs.filter(historicalJobsFilter)} />
                )}

            <div className="claimform">
                {stageHistory.map((eachStateHistory) => {
                    const questionStage = questionStages.find(
                        (stage) => stage.handle === eachStateHistory
                    );

                    if (typeof questionStage === 'undefined') {
                        throw new Error(
                            `No current stage found, trying to find: ${eachStateHistory}`
                        );
                    }

                    const isStageHidden =
                        eachStateHistory !== currentStageHandle ? styles.hiddenStage : '';

                    return (
                        <div className={isStageHidden} key={eachStateHistory}>
                            <CurrentStage
                                currentStage={questionStage}
                                formState={formState}
                                onStageSubmit={onStageSubmit}
                                contractId={customerData.contractId}
                            />
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default Form;
