import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import NavbarLogout from 'src/features/logout/components/NavBarLogout';
import Loader from 'src/components/Loader';
import ErrorBar from 'src/components/ErrorBar';
import Layout from 'src/components/Layout';
import { useAuthenticatedUser } from 'src/hooks/useAuthenticatedUser';
import { Email, ExpandArrow, PurplePhone } from 'src/images';
import { Routes } from 'src/types/Routes';
import { mixpanelTrackPage } from 'src/utils/mixpanel';
import { newVirtualPageView } from 'src/utils/analytics';
import styles from './index.module.scss';
import { useGetAccount } from '../../hooks/useGetAccount';

const Profile = () => {
    const pageCount = 0;
    const location = useLocation();

    useEffect(() => {
        newVirtualPageView('engineer-account-profile', location.pathname);
        mixpanelTrackPage();
    }, [pageCount, location]);

    const {
        isLoading: authenticatedUserLoading,
        isError: authenticatedUserError,
        data: authenticatedUser,
    } = useAuthenticatedUser();
    const { data: accountData, isLoading: accountIsLoading, error: accountError } = useGetAccount();

    if (accountIsLoading || authenticatedUserLoading) {
        return <Loader />;
    }

    if (
        accountError ||
        typeof accountData === 'undefined' ||
        authenticatedUserError ||
        typeof authenticatedUser === 'undefined'
    ) {
        return (
            <ErrorBar message="An error occured whilst fetching your profile information, please contact administration" />
        );
    }

    return (
        <Layout>
            <article className={styles.container}>
                <div className={styles.backButton}>
                    <Link to={Routes.ENGINEER_ACCOUNT}>
                        <ExpandArrow />
                    </Link>
                </div>
                <section className={styles.account_header}>
                    <h2>Profile</h2>
                    <NavbarLogout />
                </section>
                <section className={styles.name_section}>
                    <h4 className={styles.name}>
                        {accountData.first_name} {accountData.last_name}
                    </h4>
                </section>
                <section className={styles.info}>
                    <Email />
                    {accountData.email}
                </section>
                {accountData.phone1 && (
                    <section className={styles.info}>
                        <PurplePhone />
                        {accountData.phone1_prefix} {accountData.phone1}
                    </section>
                )}
                {!accountData.phone1 && accountData.phone2 && (
                    <section className={styles.info}>
                        <PurplePhone />
                        {accountData.phone2_prefix} {accountData.phone2}
                    </section>
                )}
            </article>
        </Layout>
    );
};

export default Profile;
