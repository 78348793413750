export enum ProductEnum {
    BOILER = 'boiler',
    HEATING = 'heating',
    ELECTRIC = 'electric',
    PLUMBING = 'plumbing',
    DRAINAGE = 'drainage',
    WINDOWS = 'windows',
    LOCKS = 'locks',
    PESTS = 'pests',
    DOORS = 'doors',
    TAPS = 'taps',
    SHOWERS = 'showers',
}
