import { SkeduloJob } from 'src/types/Skedulo';
import { jobTypeMap } from '../data/jobTypeMap';

export const hasOpenJobSameType = (jobs: SkeduloJob[], answerValue: string) => {
    const openJobSameType = jobs
        .filter(({ node }) => node.JobStatus !== 'Complete' && node.JobStatus !== 'Cancelled')
        .filter(({ node }) => node.Type === jobTypeMap[answerValue]);

    return openJobSameType.length > 0;
};
