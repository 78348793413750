import { FormikErrors } from 'formik';
import { determineTimeSlotsToShow } from '../../../../../utils/dateForm';
import Checkbox from './Checkbox';

type Props = {
    title: string;
    subtitle?: string;
    timeSlotsToShow: ReturnType<typeof determineTimeSlotsToShow>;
    selectedTimes?: string[];
    name: string;
    onChange: (e: React.FormEvent<HTMLInputElement>) => void;
    errors: FormikErrors<any>;
};
const ToggleForm = ({
    title,
    subtitle,
    timeSlotsToShow,
    selectedTimes,
    name,
    onChange,
    errors,
}: Props) => (
    <div id={name} className="claimform-inputblock">
        <h2>
            {title}
            <span className="claimform-red">*</span>
        </h2>
        {subtitle ? <h4>{subtitle}</h4> : ''}
        {timeSlotsToShow.map((timeslot) => {
            const isChecked =
                Array.isArray(selectedTimes) && selectedTimes.includes(timeslot.value);
            return (
                <Checkbox
                    key={timeslot.label.join('-')}
                    name={name}
                    value={timeslot.value}
                    label={timeslot.label}
                    onChange={onChange}
                    isChecked={isChecked}
                />
            );
        })}
        {errors[name] ? <div className="fnolform-error">{errors[name]}</div> : false}
    </div>
);
export default ToggleForm;
