import { JobDetails } from 'src/types/Job';
import {
    FormState,
    EstimatedCostOfPartsValue,
    BoilerDetailsValue,
    FollowOnDetails,
    BoilerGaugesValue,
    ActualCostOfPartsValue,
    Part,
} from './Form';
import { JobPart } from './JobParts';

export interface Config {
    options?: ConfigOption[];
    completeCopy?: string;
    otherConfig?: {
        question?: string;
        className?: string;
    };
    singleOption?: string;
    text?: {
        fieldLength: number;
    };
    type?: string;
}
export interface ConfigOption {
    value: string;
    copy?: string;
    default?: boolean;
}

export interface Option {
    value: string;
    copy?: string;
    // TODO combine nextQuestion and nextQuestionSelector. A question Option object should always have either a nextQuestion or a nextQuestionSelector
    nextQuestion?: string;
    nextQuestionSelector?: (value: OnSubmitValue, formState: Partial<FormState>) => string;
}

export enum QuestionType {
    SELECT = 'select',
    TEXT = 'text',
    SINGLE_TEXT = 'singleText',
    DROPDOWN = 'dropdown',
    CURRENCY = 'currency',
    FILE_UPLOAD = 'fileUpload',
    COMPLETE = 'complete',
    FOLLOW_ON_REQUIRED = 'followOnRequired',
    ESTIMATED_COST_OF_PARTS = 'estimatedCostOfParts',
    CONFIRM_ESTIMATED_COST_OF_PARTS = 'confirmEstimatedCostOfParts',
    ACTUAL_COST_OF_PARTS = 'actualCostOfParts',
    ACTUAL_HOURS = 'actualHours',
    BOILER_DETAILS = 'boilerDetails',
    FOLLOW_ON_DETAILS = 'followOnDetails',
    BOILER_GAUGES = 'boilerGauges',
    AUTHORISED = 'authorised',
    COST_AUTHORISATION = 'costAuthorisation',
    CHECKBOX = 'checkbox',
    INVESTIGATION_REQUIRED = 'investigationRequired',
    PARTS_NEEDED = 'partsNeeded',
    RADIO = 'radio',
    NEW_ACTUAL_COST_OF_PARTS = 'newActualCostOfParts',
    NEW_CONFIRM_ESTIMATED_COST_OF_PARTS = 'newConfirmEstimatedCostOfParts',
}

export type OnSubmitValue =
    | string
    | string[]
    | number
    | EstimatedCostOfPartsValue
    | BoilerDetailsValue
    | FollowOnDetails
    | null
    | BoilerGaugesValue
    | ActualCostOfPartsValue;

export interface Question {
    question: string;
    body?: string;
    type: QuestionType;
    options: Option[];
    jobDetails?: JobDetails;
    onSubmit: (value: OnSubmitValue, formState: Partial<FormState>) => Partial<FormState> | any;
    config?: Config;
    isOptional?: boolean;
    defaultValue?: Partial<FormState> | string | string[] | number | null | Part[] | JobPart[];
}

export interface Questions {
    [key: string]: Question;
}
