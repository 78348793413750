import { JSONSchemaType } from 'ajv';
import { Part } from 'src/features/closeJob/types/Form';
import { JobUrgency } from './JobUrgency';

export enum JobType {
    DRAINS = 'Drains',
    ELECTRIC = 'Electric',
    GAS_JOB = 'Gas Job',
    ANNUAL_SERVICE = 'Annual Service',
    PLUMBING = 'Plumbing',
    GAS_JOB_REATTEND = 'Gas Job re-attend',
}

export enum VisitType {
    FOLLOW_ON = 'FollowOn',
    REATTEND = 'Reattend',
    FOLLOW_ON_FROM_PMS = 'FollowOnFromPMS',
    RESCHEDULE = 'Reschedule',
    FIXED_PRICE_REPAIR = 'FixedPriceRepair',
    BREAKDOWN = 'Breakdown',
}

export enum AreaOfIssue {
    BOILER = 'boiler',
    HEATING = 'heating',
    ELECTRIC = 'electric',
    PLUMBING = 'plumbing',
    DRAINAGE = 'drainage',
    WINDOWS = 'windows',
    LOCKS = 'locks',
    PESTS = 'pests',
    DOORS = 'doors',
}

export type JobDetails = {
    id: number;
    sk_job_id: string;
    sk_job_name: string;
    sk_resource_id: string;
    first_name: string;
    last_name: string;
    phone1_prefix?: string | null;
    phone1?: string | null;
    phone2_prefix?: string | null;
    phone2?: string | null;
    phoneNumber: string | undefined;
    address_line_1: string;
    address_line_2?: string | null;
    address_line_3?: string | null;
    postcode: string;
    town: string;
    claim_id: number;
    claim_status: string;
    claim_status_id: number;
    claim_status_name: string;
    job_type: JobType;
    sk_authorise_follow_on: string | null;
    sk_job_status: string;
    summary: string;
    sk_urgency?: JobUrgency | null;
    sk_vulnerable_customer?: string | null;
    end?: string | null;
    start?: string | null;
    sk_estimated_start?: string | null;
    sk_estimated_end?: string | null;
    sk_start?: string | null;
    sk_end?: string | null;
    sk_actual_start?: string | null;
    sk_actual_end?: string | null;
    sk_estimated_additional_hours_authorised?: number | null;
    sk_estimated_e_parts_net_amount?: number | null;
    sk_visit_type?: VisitType | null;
    sk_additional_hours_authorised?: string | null;
    engineer_vat_number?: string | null;
    package_id?: number | null;
    package_bundle_name?: string;
    sk_boiler_make: string | null;
    sk_boiler_model: string | null;
    sk_boiler_gc_number: string | null;
    sk_gas_rate_kw: number | null;
    sk_boiler_type: string | null;
    sk_boiler_age: string | null;
    sk_burner_pressure_mb: number | null;
    sk_boiler_location: string | null;
    sk_visual_inspection_safe: string | null;
    sk_safety_device_fsf_correct: number | null;
    sk_ventillation_size_config_labelling: 'Yes' | 'NoIfAROrID' | 'No' | null;
    sk_combustion_performance_reading: string | null;
    sk_unsafe_situations_identified: 'AtRisk' | 'ImmediatelyDangerous' | 'NA' | null;
    sk_confidence_in_diagnosis: number | null;
    sk_e_parts_description: string | null;
    previous_engineer_visits: number | string;
    number_of_cancelled_jobs_in_claim: number;
    parts: Part[] | null;
};

export type JobDetailsErrorResponse = {
    message: string;
};

export type JobDetailsResponse = JobDetails | JobDetailsErrorResponse;

export const jobDetailsSchema: JSONSchemaType<JobDetails> = {
    type: 'object',
    properties: {
        id: { type: 'number' },
        sk_job_id: { type: 'string' },
        sk_job_name: { type: 'string' },
        sk_resource_id: { type: 'string' },
        first_name: { type: 'string' },
        last_name: { type: 'string' },
        phone1_prefix: { type: 'string', nullable: true },
        phone1: { type: 'string', nullable: true },
        phone2_prefix: { type: 'string', nullable: true },
        phone2: { type: 'string', nullable: true },
        phoneNumber: { type: 'string', nullable: true },
        address_line_1: { type: 'string' },
        address_line_2: { type: 'string', nullable: true },
        address_line_3: { type: 'string', nullable: true },
        postcode: { type: 'string' },
        town: { type: 'string' },
        claim_id: { type: 'number' },
        claim_status: { type: 'string' },
        claim_status_id: { type: 'number' },
        claim_status_name: { type: 'string' },
        job_type: { type: 'string', enum: [...Object.values(JobType), null] as readonly JobType[] },
        sk_job_status: { type: 'string' },
        summary: { type: 'string' },
        sk_urgency: { type: 'string', nullable: true },
        sk_vulnerable_customer: { type: 'string', nullable: true },
        start: { type: 'string', nullable: true },
        end: { type: 'string', nullable: true },
        sk_start: { type: 'string', nullable: true },
        sk_end: { type: 'string', nullable: true },
        sk_estimated_start: { type: 'string', nullable: true },
        sk_estimated_end: { type: 'string', nullable: true },
        sk_actual_start: { type: 'string', nullable: true },
        sk_actual_end: { type: 'string', nullable: true },
        sk_authorise_follow_on: { type: 'string', nullable: true },
        sk_estimated_additional_hours_authorised: { type: 'number', nullable: true },
        sk_estimated_e_parts_net_amount: { type: 'number', nullable: true },
        sk_visit_type: {
            type: 'string',
            enum: [...Object.values(VisitType), null] as readonly VisitType[],
            nullable: true,
        },
        sk_additional_hours_authorised: { type: 'string', nullable: true },
        engineer_vat_number: { type: 'string', nullable: true },
        package_id: { type: 'number', nullable: true },
        package_bundle_name: { type: 'string', nullable: true },
        sk_boiler_make: { type: 'string', nullable: true },
        sk_boiler_model: { type: 'string', nullable: true },
        sk_boiler_gc_number: { type: 'string', nullable: true },
        sk_gas_rate_kw: { type: 'number', nullable: true },
        sk_boiler_type: { type: 'string', nullable: true },
        sk_boiler_age: { type: 'string', nullable: true },
        sk_burner_pressure_mb: { type: 'number', nullable: true },
        sk_boiler_location: { type: 'string', nullable: true },
        sk_visual_inspection_safe: { type: 'string', nullable: true },
        sk_safety_device_fsf_correct: { type: 'number', nullable: true },
        previous_engineer_visits: { type: 'number', nullable: true },
        sk_ventillation_size_config_labelling: {
            type: 'string',
            enum: ['Yes', 'NoIfAROrID', 'No', null],
            nullable: true,
        },
        sk_combustion_performance_reading: { type: 'string', nullable: true },
        sk_unsafe_situations_identified: {
            type: 'string',
            enum: ['AtRisk', 'ImmediatelyDangerous', 'NA', null],
            nullable: true,
        },
        sk_confidence_in_diagnosis: {
            type: 'number',
            nullable: true,
        },
        sk_e_parts_description: {
            type: 'string',
            nullable: true,
        },
        number_of_cancelled_jobs_in_claim: {
            type: 'number',
            nullable: false,
        },
        parts: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    quantity: { type: 'number', nullable: true },
                    actuallyUsed: { type: 'boolean', nullable: true },
                    estimatedCost: { type: 'number', nullable: true },
                    actualCost: { type: 'number', nullable: true },
                    name: { type: 'string' },
                    estimated_hours_primary: { type: 'number', nullable: true },
                    estimated_hours_secondary: { type: 'number', nullable: true },
                    max_tolerance: { type: 'number', nullable: true },
                },
                required: ['name'],
            },
            nullable: true,
        },
    },
    required: [
        'id',
        'sk_job_id',
        'sk_job_name',
        'sk_resource_id',
        'first_name',
        'last_name',
        'address_line_1',
        'postcode',
        'town',
        'claim_id',
        'claim_status',
        'claim_status_id',
        'claim_status_name',
        'job_type',
        'sk_job_status',
        'summary',
    ],
};
