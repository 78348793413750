import Button from 'src/components/Button';
import { ClaimStatus } from 'src/features/newClaim/api/getPandoraJob.types';
import { SkeduloJob } from '../../../../types/Skedulo';
import styles from './index.module.scss';
import { historicalJobsFilter } from '../../utils/historicalJobsFilter';
import { getCopyForJob } from '../../utils/getCopyForCurrentClaim';

const byMostRecentFirst = (a: SkeduloJob, b: SkeduloJob) => {
    const bTime = new Date(b.node.CreatedDate).getTime();
    const aTime = new Date(a.node.CreatedDate).getTime();

    return bTime - aTime;
};

interface JobTableProps {
    jobs: any[];
}

export const JobTable = ({ jobs }: JobTableProps) => {
    // Initial solution is to show only latest job
    const firstJobData = jobs.filter(historicalJobsFilter).sort(byMostRecentFirst)[0];

    if (!firstJobData) {
        return null;
    }

    const {
        node: { Name, JobStatus: jobStatus, pandoraClaimStatus, abortReason },
    } = firstJobData;

    const copy = getCopyForJob(jobStatus, pandoraClaimStatus, abortReason);

    return (
        <div className={`claimform ${styles.container}`}>
            <section className={styles.header}>
                <h2>Latest job</h2>
                {pandoraClaimStatus !== ClaimStatus.CLOSED ? (
                    <Button
                        customStyle={styles.cancelBtn}
                        onClick={() =>
                            window.open(
                                'https://hometree.zendesk.com/hc/en-us/requests/new',
                                '_blank',
                                'noopener noreferrer'
                            )
                        }
                    >
                        Cancel/Reschedule
                    </Button>
                ) : null}
            </section>
            <section className={styles['table-container']}>
                <article>
                    <h3>Job Name</h3>
                    <span>{Name}</span>
                </article>
                <article>
                    <h3>Status</h3>
                    {copy.summary}
                </article>
                <article>
                    <h3>Description</h3>
                    {copy.description}
                </article>
            </section>
        </div>
    );
};
