import { Part } from 'src/features/closeJob/types/Form';

export interface CanSendToManufacturerInput {
    partsRequested: Part[];
    boilerMake: string;
    issueArea: string;
}

export const canSendToManufacturer = (value: CanSendToManufacturerInput): boolean => {
    const supportedBoilerMakes = [
        'worcester-bosch',
        'worcester bosch',
        'ideal',
        'baxi',
        'vaillant',
        'glow-worm',
        'saunier duval',
        'potterton',
        'remeha',
        'main',
    ];

    const normalizeBoilerMake = value.boilerMake.toLowerCase().trim();
    const normalizedIssueArea = value.issueArea.toLowerCase().trim();
    if (normalizedIssueArea !== 'boiler') {
        return false;
    }

    if (supportedBoilerMakes.includes(normalizeBoilerMake)) {
        if (['baxi', 'potterton', 'remeha', 'main'].includes(normalizeBoilerMake)) {
            return true;
        }

        if (value.partsRequested.some((part) => part.name === 'Main Heat Exchanger')) {
            return false;
        }

        return true;
    }

    return false;
};
