import styles from './index.module.scss';
import { Config } from '../../types/Question';

interface TextProps {
    name: string;
    title: string;
    config: Config;
}
const Text = ({ title = 'Completed!', name, config }: TextProps) => {
    return (
        <div className={styles.textContainer} data-testid={name}>
            <h1 className={styles.title}>{title}</h1>
            {config.extraCopy && <div className={styles.extraCopy}>{config.extraCopy}</div>}
            <hr />
        </div>
    );
};

export default Text;
