import Modal from 'src/components/Modal';
import { useEffect, useState } from 'react';
import { Account } from 'src/features/account/customer/profile/api/getAccount.types';
import Checkbox from 'src/components/checkbox';
import { useUpdateAccount } from 'src/features/account/customer/profile/hooks/updateAccount';
import toast from 'react-hot-toast';
import toastConfig from 'src/utils/toastConfig';
import { newAnalyticsEvent, newVirtualPageView } from 'src/utils/analytics';
import styles from './index.module.scss';

export const DashboardModal = ({
    account,
    isModalOpen,
    setIsModalOpen,
}: {
    account: Account;
    isModalOpen: boolean;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const [isCheckboxChecked, setIsCheckboxChecked] = useState({
        email: !!account.marketing_email,
        text: !!account.marketing_sms,
        phone: !!account.marketing_phone,
    });
    const marketingTypes = ['email', 'phone', 'text'];

    const { mutate, isSuccess, isError } = useUpdateAccount();

    useEffect(() => {
        if (isSuccess) {
            toast.success(`Successfully updated Marketing Preferences`, toastConfig);
            setIsModalOpen(false);
        } else if (isError) {
            toast.error(
                `We were unable to save your marketing preferences. Please try again later or contact us.`,
                toastConfig
            );
        }
    }, [isSuccess, setIsModalOpen, isError]);

    useEffect(() => {
        sessionStorage.setItem('marketingConsent', 'true');
        newAnalyticsEvent({
            category: 'Marketing_Consent',
            action: 'pop-up-consent-displayed',
            label: `${account.id}`,
            value: `${account.id}`,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClick = () => {
        const marketingData = {
            marketing_email: !!isCheckboxChecked.email,
            marketing_sms: !!isCheckboxChecked.text,
            marketing_phone: !!isCheckboxChecked.phone,
        };
        mutate(marketingData);

        const selectedValues = marketingTypes.filter((type) => isCheckboxChecked[type]);
        newVirtualPageView('customer-dashboard-page', '/dashboard');
        newAnalyticsEvent({
            category: 'Marketing_Consent',
            action: selectedValues.length ? 'pop-up-consent-given' : 'pop-up-consent-not-given',
            label: `${account.id}`,
            value: selectedValues.toString(),
        });
    };

    return (
        <Modal
            isOpen={isModalOpen}
            setIsOpen={setIsModalOpen}
            handleOnClick={handleClick}
            style={{ color: '#160043' }}
            btnText="Confirm"
            btnClass={styles.confirmBtn}
            handleOnClose={() =>
                newAnalyticsEvent({
                    category: 'Marketing_Consent',
                    action: 'pop-up-consent-closed',
                    label: `${account.id}`,
                    value: '',
                })
            }
        >
            <>
                <h2>Keeping in touch</h2>
                <div className={styles.description}>
                    <p>
                        Occasionally, we&apos;d like to keep you informed about the latest offers
                        and products from Hometree Group, along with updates about the Hometree
                        referral program.
                    </p>
                    <p>
                        Please let us know how you would like us to keep in touch below. You can
                        update these preferences here in the customer portal at any time.
                    </p>
                </div>
                <div className={styles.checkboxContainer}>
                    {marketingTypes.map((type) => (
                        <div key={type} className={styles.checkboxes}>
                            <Checkbox
                                id={`enable-${type}`}
                                onChange={(e) => {
                                    setIsCheckboxChecked({
                                        ...isCheckboxChecked,
                                        [type]: e.target.checked,
                                    });
                                }}
                                name={`enable-${type}`}
                                checked={isCheckboxChecked[type]}
                            >
                                <label htmlFor={`enable-${type}`}>
                                    {type.charAt(0).toUpperCase()}
                                    {type.slice(1)}
                                </label>
                            </Checkbox>
                        </div>
                    ))}
                </div>
            </>
        </Modal>
    );
};
