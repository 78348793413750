import { diffDateDays } from './dates';

export const calculateJobEndDateRange = (range = 30) => {
    const RANGE_IN_MILLIS = range * 24 * 60 * 60 * 1000;
    return new Date(Date.now() - RANGE_IN_MILLIS);
};

export const getMostRecentReattendJobName = (jobs, jobType) => {
    const jobEndDateRange = calculateJobEndDateRange();
    const onlyLastMonthJobs = ({ node }) => node.ActualEnd > jobEndDateRange.toISOString();

    const byMostRecentFirst = (a, b) => {
        const bTime = new Date(b.node.ActualEnd).getTime();
        const aTime = new Date(a.node.ActualEnd).getTime();

        return bTime - aTime;
    };

    const onlyCompletedJobs = ({ node }) => node.JobStatus === 'Complete';
    const onlyReattends = ({ node }) =>
        node.Type === jobType && diffDateDays(new Date(node.ActualEnd), new Date(Date.now())) < 31;

    const jobName = ({ node }) => node.Name;

    const jobNames = jobs
        .filter(onlyLastMonthJobs)
        .sort(byMostRecentFirst)
        .filter(onlyCompletedJobs)
        .filter(onlyReattends)
        .map(jobName);

    return jobNames[0] || null;
};
