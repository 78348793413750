import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Layout from 'src/components/Layout';
import { Routes } from '../../../types/Routes';
import styles from './index.module.scss';

import { useAccountQuery } from '../../../hooks/useAccountQuery';
import Loader from '../../../components/Loader';
import ErrorBar from '../../../components/ErrorBar';
import { HOMETREE_PHONE_NUMBER } from '../../../constants';
import ContractsList, { isContractWithinCoolingOffPeriod } from './ContractsList';

const ERROR_NO_CONTRACTS = `No contracts found for account. Please give us a call on ${HOMETREE_PHONE_NUMBER} and we will be able to help you.`;

export const Properties = () => {
    const { isLoading, isError, error, data } = useAccountQuery();
    const navigate = useNavigate();

    useEffect(() => {
        // When only one contract,
        // And that contract is not within the cool off period,
        // Go straight to claim form
        if (
            data?.contracts?.length === 1 &&
            !isContractWithinCoolingOffPeriod(
                new Date(data.contracts[0].startDate),
                data.contracts[0].isRenewal
            )
        ) {
            const contract = data.contracts[0];
            navigate(Routes.CLAIM_NEW, {
                state: {
                    contractId: contract.id,
                },
            });
        }
    }, [data, navigate]);

    const navigateToNewClaim = (contractId: number) =>
        navigate(Routes.CLAIM_NEW, {
            state: { contractId },
        });

    if (isLoading) {
        return <Loader />;
    }

    if (isError) {
        return <ErrorBar message={(error as Error).message} style={styles.propertiesErrorBar} />;
    }

    if (!data || !data.contracts || data.contracts.length === 0) {
        return <ErrorBar message={ERROR_NO_CONTRACTS} style={styles.propertiesErrorBar} />;
    }

    return (
        <>
            <Layout>
                <div className={`container ${styles.properties}`}>
                    <p className={styles.subText}>
                        Please select the property you wish to request a repair for
                    </p>
                    <ContractsList contracts={data.contracts} onSelect={navigateToNewClaim} />
                </div>
            </Layout>
        </>
    );
};
