import styles from './index.module.scss';

type BubbleHeadingProps = {
    heading: React.ReactNode;
    subheading: React.ReactNode;
};

const BubbleHeading = ({ heading, subheading }: BubbleHeadingProps): JSX.Element => {
    return (
        <div className={styles.heading}>
            <h1>{heading}</h1>
            <div className={styles.subheading}>
                <h4>{subheading}</h4>
            </div>
        </div>
    );
};

export default BubbleHeading;
