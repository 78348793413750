import { HAS_SERVICE_ANSWER, determineJobUrgency } from 'src/utils/jobs';
import { CustomerData } from 'src/types/CustomerData';
import { FormState } from '../types/Form';
import { QuestionType, QuestionStage } from '../types/Question';

export const boilerQuestions = (_customerData: CustomerData): QuestionStage[] => {
    return [
        {
            handle: 'boilerGroup1',
            questions: [
                {
                    name: 'gasLeak',
                    description: 'Is there a Gas leak?',
                    type: QuestionType.RADIO,
                    isRequired: true,
                    config: {
                        options: [
                            { copy: 'Yes', value: 'Yes' },
                            { copy: 'No', value: 'No' },
                        ],
                    },
                },
                {
                    name: 'problemType',
                    description: 'Do you have any of the following?',
                    type: QuestionType.RADIO,
                    isRequired: true,
                    config: {
                        options: [
                            {
                                copy: 'Complete loss of hot water, heating, or both',
                                value: 'Complete loss of hot water, heating, or both',
                            },
                            {
                                copy: 'Major uncontrollable water leak that requires me to turn off water supply',
                                value: 'Major uncontrollable water leak that requires me to turn off water supply',
                            },
                            {
                                copy: HAS_SERVICE_ANSWER,
                                value: HAS_SERVICE_ANSWER,
                            },
                        ],
                    },
                },
                {
                    name: 'gasHeat',
                    description: 'Is the issue related to underfloor heating or heat pumps?',
                    type: QuestionType.RADIO,
                    isRequired: true,
                    config: {
                        options: [
                            { copy: 'Yes', value: 'Yes' },
                            { copy: 'No', value: 'No' },
                        ],
                    },
                },
            ],
            onSubmit: (values, formState) => {
                const newFormState = {
                    gasLeak: values.gasLeak,
                    problemType: values.problemType,
                    gasHeat: values.gasHeat,
                    urgency: determineJobUrgency(
                        formState.vulnerable || '',
                        String(values.problemType),
                        formState.insecureProperty || '',
                        formState.lockedOutFromProperty || '',
                        formState.doorsSecureBuilding || ''
                    ),
                } as Partial<FormState>;

                if (values.gasLeak !== 'Yes' && values.gasHeat === 'Yes') {
                    newFormState.rejectProduct = 'underfloor heating and heat pumps';
                }

                return newFormState;
            },
            options: [
                {
                    copy: 'Next',
                    nextQuestion: (values) => {
                        if (values.gasLeak === 'Yes') {
                            return 'gasLeakRejection';
                        }

                        if (values.gasHeat === 'Yes') {
                            return 'optionNotCovered';
                        }

                        if (
                            values.problemType === HAS_SERVICE_ANSWER &&
                            _customerData.packageDetails &&
                            _customerData.packageDetails.onlyLossOfService === true &&
                            _customerData.packageDetails.requiresInsurerApproval === true
                        ) {
                            return 'lossOfServiceNotCoveredNoExtraCopy';
                        }

                        if (
                            values.problemType === HAS_SERVICE_ANSWER &&
                            _customerData.packageDetails &&
                            _customerData.packageDetails.onlyLossOfService === true
                        ) {
                            return 'lossOfServiceNotCovered';
                        }

                        return 'boilerGroup2';
                    },
                },
            ],
        },
        {
            handle: 'boilerGroup2',
            questions: [
                {
                    name: 'boilerMaker',
                    description: 'What is the make of your boiler?',
                    type: QuestionType.DROPDOWN,
                    isRequired: true,
                    config: {
                        options: [
                            { copy: 'Worcester Bosch', value: 'worcester-bosch' },
                            { copy: 'Vaillant', value: 'vaillant' },
                            { copy: 'Ideal', value: 'ideal' },
                            { copy: 'Baxi', value: 'baxi' },
                            { copy: 'Potterton', value: 'potterton' },
                            { copy: 'Alpha', value: 'alpha' },
                            { copy: 'Glow-worm', value: 'glow-worm' },
                            { copy: 'Ariston', value: 'ariston' },
                            { copy: 'Ferroli', value: 'ferroli' },
                            { copy: 'Viessman', value: 'viessman' },
                            { copy: 'Vokera', value: 'vokera' },
                            { copy: 'Saunier Duval', value: 'saunier duval' },
                            { copy: 'Remeha', value: 'remeha' },
                            { copy: 'Main', value: 'main' },
                            { copy: 'Other', value: 'other' },
                        ],
                        placeholder: 'Please select...',
                        otherTitle: 'Please specify...',
                    },
                },
                {
                    name: 'boilerModel',
                    description: 'What is the model of your boiler?',
                    type: QuestionType.INPUT_TEXT,
                    isRequired: true,
                },
                {
                    name: 'boilerFaultCode',
                    description: 'Is there any fault code on the boiler?',
                    type: QuestionType.INPUT_TEXT,
                    isRequired: false,
                },
                {
                    name: 'boilerAge',
                    description: 'How many years ago was your boiler installed?',
                    type: QuestionType.RADIO,
                    isRequired: true,
                    config: {
                        options: [
                            {
                                value: '1-3',
                                copy: '1-3 years old',
                            },
                            {
                                value: '3-5',
                                copy: '3-5 years old',
                            },
                            {
                                value: '5-10',
                                copy: '5-10 years old',
                            },
                            {
                                value: '10+',
                                copy: '10+ years old',
                            },
                            {
                                value: 'not sure',
                                copy: 'Not sure',
                            },
                        ],
                    },
                },
            ],
            onSubmit: (values) =>
                ({
                    boilerMaker: values.boilerMaker,
                    boilerModel: values.boilerModel,
                    boilerFaultCode: values.boilerFaultCode,
                    boilerAge: values.boilerAge,
                } as Partial<FormState>),
            options: [
                {
                    copy: 'Next',
                    nextQuestion: 'vulnerability',
                },
            ],
        },
        {
            handle: 'gasLeakRejection',
            questions: [
                {
                    name: 'failed',
                    description: '',
                    type: QuestionType.FAILED,
                    isRequired: true,
                    config: {
                        extraCopy: (
                            <>
                                <p>
                                    If there is a gas leak, call the <br />
                                    <strong>
                                        National Gas Emergency Helpline on{' '}
                                        <a href="tel:0800111999">0800 111 999</a>
                                    </strong>
                                </p>

                                <p>
                                    <ul>
                                        <li>
                                            <strong>Turn off the gas at the meter</strong>
                                        </li>
                                        <li>
                                            <strong>Open windows and doors</strong>
                                        </li>
                                        <li>
                                            <strong>Extinguish all flames</strong>
                                        </li>
                                    </ul>
                                </p>
                                <p>
                                    Once the National Grid has attended, let us know and we&apos;ll
                                    get someone out to you.
                                </p>
                            </>
                        ),
                    },
                },
            ],
            onSubmit: () => {},
            config: {},
        },
    ];
};
