import Layout from 'src/components/Layout';
import { AnnualService, BoookARepair, YourAccount } from 'src/images';
import { Routes } from 'src/types/Routes';
import Banner from 'src/components/Banner';
import { useGetAccount } from 'src/features/account/customer/profile/hooks/useGetAccount';
import { useAuthenticatedUser } from 'src/hooks/useAuthenticatedUser';
import Loader from 'src/components/Loader';
import ErrorBar from 'src/components/ErrorBar';
import { useEffect, useState } from 'react';
import { newAnalyticsEvent } from 'src/utils/analytics';
import { getMandatoryEnvVar } from 'src/utils/env';
import styles from './index.module.scss';
import { DashboardModal } from './DashboardModal';

const dashboardOptions = [
    {
        icon: 'BoookARepair',
        text: 'Request a Repair',
        path: Routes.CLAIM_NEW,
    },
    {
        icon: 'AnnualService',
        text: 'Annual Service',
        path: 'https://www.hometree.co.uk/help-centre/covid-19/when-will-my-annual-service-visit-take-place',
        target: '_blank',
    },
    {
        icon: 'YourAccount',
        text: 'Your Account',
        path: Routes.CUSTOMER_ACCOUNT,
    },
];

const determineIcon = (text: string) => {
    switch (text) {
        case 'BoookARepair':
            return <BoookARepair />;
        case 'AnnualService':
            return <AnnualService />;
        case 'YourAccount':
            return <YourAccount />;
        default:
            return null;
    }
};

export const Dashboard = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const {
        isLoading: authenticatedUserLoading,
        isError: authenticatedUserError,
        data: authenticatedUser,
    } = useAuthenticatedUser();
    const { data: accountData, isLoading: accountIsLoading, error: accountError } = useGetAccount();
    const errorBannerEnabled = getMandatoryEnvVar('REACT_APP_CUSTOMER_ERROR_BANNER_ENABLED');
    const errorBannerText = getMandatoryEnvVar('REACT_APP_CUSTOMER_ERROR_BANNER_TEXT');

    useEffect(() => {
        const consent = sessionStorage.getItem('marketingConsent');
        if (accountData?.marketing === false && !consent) {
            setIsModalOpen(true);
        }
    }, [accountData]);

    if (accountIsLoading || authenticatedUserLoading) {
        return <Loader />;
    }

    if (
        accountError ||
        typeof accountData === 'undefined' ||
        authenticatedUserError ||
        typeof authenticatedUser === 'undefined'
    ) {
        return (
            <ErrorBar message="An error occured whilst fetching your account information, please contact administration" />
        );
    }

    return (
        <div className={styles.dashboard}>
            {errorBannerEnabled === 'true' && <Banner body={errorBannerText} />}
            <Layout mode="wide">
                <h1 className={styles.title}>What are you looking for?</h1>
                <div className={styles.carouselContainer}>
                    <div className={styles.options}>
                        {dashboardOptions.map((option) => (
                            <a
                                href={option.path}
                                key={option.text}
                                className={styles.option}
                                data-disabled={!option.path}
                                data-icon={option.icon}
                                target={option.target ? option.target : '_self'}
                                rel="noopener noreferrer"
                            >
                                <div className={styles.icon}>{determineIcon(option.icon)}</div>
                                <h3>{option.text}</h3>
                            </a>
                        ))}
                    </div>
                </div>
                <Banner
                    title="Need a new boiler? Save 15% and upgrade without the upfront cost"
                    version="green"
                    fullLink="https://hometreefinance.co.uk/new-boiler/hometree?utm_source=htcustomerportal"
                    handleClick={() =>
                        newAnalyticsEvent({
                            category: 'X_sell_Banner',
                            action: 'banner_clicked',
                            label: `${accountData.id}`,
                            value: `${accountData.id}`,
                        })
                    }
                />
                <Banner
                    version="purple"
                    title="Earn £50 with every referral"
                    subtitle={
                        <>
                            Refer a friend with your unique link and start earning
                            <b> Amazon.co.uk</b> vouchers today
                        </>
                    }
                    bannerLink={{
                        text: 'Invite a friend link',
                        link: `https://www.hometree.co.uk/referral.html?q=REF${accountData.referral_code}&utm_source=htportal&utm_medium=htportal&utm_campaign=Mar24_referral_banner`,
                        textButton: 'Share',
                    }}
                    handleClick={() =>
                        newAnalyticsEvent({
                            category: 'Referral_Banner',
                            action: 'referral-link-copied',
                            label: `${accountData.id}`,
                            value: `${accountData.id}`,
                        })
                    }
                />
            </Layout>
            {isModalOpen && accountData ? (
                <DashboardModal
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    account={accountData}
                />
            ) : null}
        </div>
    );
};
