import React, { createContext, useReducer, useContext } from 'react';
import { USER_MODES } from '../constants';
import { SkeduloJob } from '../types/Skedulo';

export type CustomerState = {
    showBillingDetailsForm: boolean;
    paymentDetailsValid: boolean;
    stripeStatus: Object;
    userMode: string;
    customerFacingId?: string;
    firstName?: string;
    lastName?: string;
    postcode?: string;
    contractId?: string;
    contribution?: number;
    stripeError?: string;
    packageId?: number;
    includedProducts?: string[];
    jobs?: SkeduloJob[];
    vulnerable?: string;
    service?: string;
    urgency?: string;
    newClaimError?: string;
};
type Action = {
    type: 'setCustomer';
    payload: Partial<CustomerState>;
};
type Dispatch = (action: Action) => void;

const CustomerContext = createContext<
    | {
          state: CustomerState;
          dispatch: Dispatch;
      }
    | undefined
>(undefined);

const CustomerReducer = (state: CustomerState, action: Action) => {
    switch (action.type) {
        case 'setCustomer': {
            return { ...state, ...action.payload };
        }
        default: {
            throw new Error(`Unknown action type: ${action.type}`);
        }
    }
};

type ProviderProps = {
    children: React.ReactNode;
    initialState?: Partial<CustomerState>;
};
const defaultState = {
    showBillingDetailsForm: true,
    paymentDetailsValid: false,
    stripeStatus: {},
    userMode: USER_MODES.CUSTOMER,
    jobs: [],
};
const CustomerProvider = ({ children, initialState = defaultState }: ProviderProps) => {
    const [state, dispatch] = useReducer(CustomerReducer, initialState as CustomerState);

    const value = { state, dispatch };
    return <CustomerContext.Provider value={value}>{children}</CustomerContext.Provider>;
};

const useCustomer = () => {
    const { state, dispatch } = useContext(CustomerContext) || {};

    if (state === undefined || dispatch === undefined)
        throw new Error('useCustomer must be used within a provider');

    return { customer: state, dispatch };
};

export { CustomerProvider, CustomerContext, useCustomer };
