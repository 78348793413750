import { SunFullIcon, SunHalfIcon } from 'src/images';

type Props = {
    name: string;
    value: string;
    label: string[];
    onChange: (e: React.FormEvent<HTMLInputElement>) => void;
    isChecked: boolean;
};

const Checkbox = ({ name, value, label, onChange, isChecked }: Props) => {
    const inputId = `${name}-${value}`;
    const labelText = () => (
        <p className="toggleform-text">
            {value !== '12:00:00' ? <SunHalfIcon /> : <SunFullIcon />}
            <>
                {label.map((labelValue) => (
                    <span key={labelValue}>{labelValue}</span>
                ))}
            </>
        </p>
    );

    return (
        <div className="toggleform">
            <input
                type="checkbox"
                name={name}
                value={value}
                id={inputId}
                onChange={onChange}
                checked={isChecked}
            />
            <label htmlFor={inputId}>
                {labelText()}
                <div className="toggleform-box">
                    <div className="toggleform-box-interior" />
                </div>
            </label>
        </div>
    );
};

export default Checkbox;
