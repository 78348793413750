export const jobTypeMap = {
    boiler: 'Gas Job',
    heating: 'Gas Job',
    electric: 'Electric',
    plumbing: 'Plumbing',
    drainage: 'Drains',
    windows: 'Windows',
    locks: 'Locks',
    pests: 'Pests',
    doors: 'Doors',
};
