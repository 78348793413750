import { Part } from 'src/features/closeJob/types/Form';
import { captureSentryInfo } from 'src/features/login/utils/captureSentryError';
import { formatPounds } from 'src/utils/prices';
import PartForm from './PartForm';

interface Props {
    setShowModal: (boolean) => void;
    setParts: (object) => void;
    parts: Part[];
    isActualCost?: boolean;
    initialPartData?: Part;
}

const PartEditModal = ({ setShowModal, setParts, parts, isActualCost, initialPartData }: Props) => {
    const onSubmit = (submittedPart: Part, totalCost: string) => {
        if (!initialPartData) {
            // TODO sentry message to notify if editing modal was missing initialPartData
            captureSentryInfo('Editing modal was missing initialPartData');
            return;
        }

        const partIndex = parts.findIndex((part) => part.name === initialPartData.name);

        if (partIndex !== -1) {
            const updatedParts = [...parts];
            updatedParts[partIndex] = {
                ...(isActualCost && {
                    actualCost: +totalCost,
                }),
                ...(!isActualCost && {
                    estimatedCost: +totalCost,
                }),
                name: submittedPart.name,
                quantity: submittedPart.quantity,
                actuallyUsed: isActualCost ? true : null,
            };
            setParts(updatedParts);
        }
    };

    const renderButtonText = (totalCost: number) =>
        totalCost ? `Update for £${formatPounds(totalCost)}` : 'Update';

    return (
        <PartForm
            setShowModal={setShowModal}
            parts={parts}
            isActualCost={isActualCost}
            initialPartData={initialPartData}
            onSubmit={onSubmit}
            renderButtonText={renderButtonText}
        />
    );
};

export default PartEditModal;
