export const USER_MODE_IDS = {
    customer: 1,
    cs: 2,
    ooh: 3,
};

export const USER_MODES = {
    CUSTOMER: 'customer',
    CUSTOMER_SUCCESS: 'cs',
    OUT_OF_HOURS: 'ooh',
    ENGINEER: 'engineer',
};

export const JOB_URGENCY = {
    NORMAL: 'Normal',
    URGENT: 'Urgent',
    CRITICAL: 'Critical',
};

export const HOMETREE_PHONE_NUMBER = '03309124843';
export const HOMETREE_ENGINEER_PHONE_NUMBER = '03300589485';

export const DAY_IN_MILLIS = 1000 * 60 * 60 * 24;

export const DEFAULT_ESTIMATED_PARTS_COST = 50;
export const DEFAULT_ESTIMATED_DURATION = 1;

export const JOB_TYPE_MAP = {
    boiler: 'Gas Job',
    heating: 'Gas Job',
    electric: 'Electric',
    plumbing: 'Plumbing',
    drainage: 'Drains',
    windows: 'Windows',
    locks: 'Locks',
    pests: 'Pests',
};
export const JOBS_PUBLIC_ENDPOINT = 'jobs-public';
export const ACCOUNT_ENDPOINT = 'accounts-public';
export const CONTRACT_ENDPOINT = 'contracts-public';
export const INTEGRATION_ENDPOINT = 'integration';

export const COST_OF_JOB_PER_HOUR = 48;
export const MAX_TOTAL_COST_FIX = 150;
export const MAX_NUMBER_OF_PARTS = 2;

export const ALLOWED_JOB_SHEET_IMAGE_FILE_TYPES = [
    'image/png',
    'image/jpeg',
    'image/jpg',
    'application/pdf',
    'image/webp',
    'image/heic',
];
