import { useState } from 'react';
import { format } from 'date-fns';
import ToggleForm from 'src/features/newClaim/components/ClaimForm/Calendar/ToggleForm';
import { allTimeSlots } from 'src/utils/dateForm';
import DateForm from 'src/features/engineerDateForm';
import { JobDetails } from 'src/types/Job';
import { JobUrgency } from 'src/types/JobUrgency';
import Button from 'src/components/Button';
import { Option } from '../../../types/Question';
import { FormState, FollowOnDetails } from '../../../types/Form';
import style from './index.module.scss';

export interface Props {
    title: string;
    options: Option[];
    onSubmit: (value: FollowOnDetails, nextQuestion?: string) => void;
    formState: Partial<FormState>;
    jobDetails: JobDetails;
}

const FollowOnQuestions = ({ title, options, onSubmit, formState, jobDetails }: Props) => {
    const today = new Date();
    const [followOnDetails, setFollowOnDetails] = useState<FollowOnDetails>({
        visitDate: '',
        timeSlot: '',
    });
    const [errors, setErrors] = useState({});

    const onChange = (key) => (e) => {
        const currentValue = e.target.value;
        const currentFieldName = e.target.name;

        if (currentValue) {
            setErrors((prev) => {
                const newErrors = { ...prev };
                delete newErrors[currentFieldName];
                return newErrors;
            });
        }

        setFollowOnDetails({
            ...followOnDetails,
            ...(currentFieldName === 'visitDate' && { timeSlot: '' }),
            [key]: currentValue,
        });
    };

    const validateFields = () => {
        const newErrors = {};
        Object.keys(followOnDetails).forEach((key) => {
            if (!followOnDetails[key]) {
                newErrors[key] = 'This field is required';
            }
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    return (
        <>
            <h3 className={style.heading}>{title}</h3>
            <div className={style.dateFormWrapper}>
                <DateForm
                    errors={errors}
                    name="visitDate"
                    onChange={onChange('visitDate')}
                    selectedDate={followOnDetails.visitDate}
                    title={
                        <>
                            <span>{format(today, 'MMMM')}</span> {format(today, 'yyyy')}
                        </>
                    }
                    urgency={jobDetails.sk_urgency || JobUrgency.NORMAL}
                />
            </div>
            {followOnDetails.visitDate && (
                <div className={style.timeSlotWrapper}>
                    <ToggleForm
                        title="What time slot is best?"
                        timeSlotsToShow={allTimeSlots}
                        name="timeSlot"
                        onChange={onChange('timeSlot')}
                        errors={errors}
                        selectedTimes={[followOnDetails.timeSlot]}
                    />
                </div>
            )}
            {options.map(({ value, nextQuestion, nextQuestionSelector }) => {
                return (
                    <Button
                        customStyle={style.button}
                        key={value}
                        type="button"
                        onClick={() => {
                            const hasErrors = !validateFields();

                            if (!hasErrors) {
                                onSubmit(
                                    followOnDetails,
                                    nextQuestionSelector
                                        ? nextQuestionSelector(followOnDetails, formState)
                                        : nextQuestion
                                );
                            }
                        }}
                    >
                        {value}
                    </Button>
                );
            })}
        </>
    );
};

export default FollowOnQuestions;
