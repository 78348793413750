import { AuthenticatedUser } from '../types/AuthenticatedUser';
import { Role } from '../types/Role';

export const getUserRole = (user: AuthenticatedUser): Role | undefined => {
    const role = user?.authenticatedUser?.attributes?.['custom:role'] as Role;

    if (!Object.values(Role).includes(role)) {
        return undefined;
    }

    return role;
};
