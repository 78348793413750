import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import NavbarLogout from 'src/features/logout/components/NavBarLogout';
import Loader from 'src/components/Loader';
import ErrorBar from 'src/components/ErrorBar';
import Layout from 'src/components/Layout';
import { mixpanelTrackPage } from 'src/utils/mixpanel';
import { newVirtualPageView } from 'src/utils/analytics';
import { useAuthenticatedUser } from 'src/hooks/useAuthenticatedUser';
import { Email, PurplePhone } from 'src/images';
import styles from './index.module.scss';
import { useGetAccount } from '../../hooks/useGetAccount';
import MarketingPreferences from './MarketingPreferences';

const CustomerAccount = () => {
    const pageCount = 0;
    const location = useLocation();

    useEffect(() => {
        newVirtualPageView('customer-account', location.pathname);
        mixpanelTrackPage();
    }, [pageCount, location]);

    const {
        isLoading: authenticatedUserLoading,
        isError: authenticatedUserError,
        data: authenticatedUser,
    } = useAuthenticatedUser();
    const { data: accountData, isLoading: accountIsLoading, error: accountError } = useGetAccount();

    if (accountIsLoading || authenticatedUserLoading) {
        return <Loader />;
    }

    if (
        accountError ||
        typeof accountData === 'undefined' ||
        authenticatedUserError ||
        typeof authenticatedUser === 'undefined'
    ) {
        return (
            <ErrorBar message="An error occured whilst fetching your account information, please contact administration" />
        );
    }

    return (
        <Layout>
            <article className={styles.container}>
                <section className={styles.account_header}>
                    <h2>Account</h2>
                    <NavbarLogout />
                </section>
                <section className={styles.name_section}>
                    <h3 className={styles.name}>
                        {accountData.first_name} {accountData.last_name}
                    </h3>
                </section>
                <section className={styles.info}>
                    <Email />
                    {accountData.email}
                </section>
                {accountData.phone1 && (
                    <section className={styles.info}>
                        <PurplePhone />
                        {accountData.phone1_prefix} {accountData.phone1}
                    </section>
                )}
                {accountData.phone2 && (
                    <section className={styles.info}>
                        <PurplePhone />
                        {accountData.phone2_prefix} {accountData.phone2}
                    </section>
                )}
                <section>
                    <MarketingPreferences account={accountData} />
                </section>
            </article>
        </Layout>
    );
};

export default CustomerAccount;
