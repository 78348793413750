import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Layout from 'src/components/Layout';
import { HOMETREE_PHONE_NUMBER } from 'src/constants';
import styles from './index.module.scss';
// import { ClaimForm } from '../ClaimForm';
import ErrorBar from '../../../../components/ErrorBar';
import Loader from '../../../../components/Loader';
import { EMAIL_AND_POSTCODE_REQUIRED, useCustomerQuery } from '../../../../hooks/useCustomerQuery';
import { newAnalyticsEvent, newVirtualPageView } from '../../../../utils/analytics';
import { getUserModeId } from '../../../../utils/userMode';
import { Routes } from '../../../../types/Routes';
import Form from '../Form';
import { questionStages } from '../../config/questions';
import { END_STAGE_HANDLES, INITIAL_STAGE_HANDLE } from '../../data/stageConfig';
import { FormState, Reattend } from '../../types/Form';
import { jobTypeMap } from '../../data/jobTypeMap';
import { getMostRecentReattendJobName } from '../../utils/jobs';
import { createClaim } from '../../api/createClaim';
import { FnolTrackingRequestBody, trackFnolData } from '../../api/fnolTracking';

interface Props {
    userMode?: any;
    onUpdate: (claimState: any) => void;
}

const NewClaimOldAuth = ({ userMode, onUpdate }: Props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formState, setFormState] = useState<Partial<FormState>>({});
    const [newClaimError, setNewClaimError] = useState('');
    const [fnolTrackingId, setFnolTrackingId] = useState<number | null>(null);
    const [reattend, setReattend] = useState<Reattend>(Reattend.NO);
    const [relatedJob, setRelatedJob] = useState<string | null>(null);

    const { email, postcode: locationPostcode } = (location.state as any) || {};
    const {
        data: customerQueryData,
        isLoading,
        isError,
        error: customerQueryError,
    } = useCustomerQuery(email, locationPostcode);

    useEffect(() => {
        // Wait until data is retrieved for customer
        if (customerQueryData?.customerFacingId) {
            newVirtualPageView('FNOL', '/vpv/fnol');
            newAnalyticsEvent({
                category: 'FNOL',
                action: 'Landed',
                label: customerQueryData.customerFacingId,
                value: getUserModeId(userMode),
            });
        }
    }, [userMode, customerQueryData, location]);

    useEffect(() => {
        // Wait until data is retrieved for customer
        if (customerQueryData?.jobs) {
            const jobTypeMapped = jobTypeMap[formState.jobType || ''];

            const mostRecentReattendJobName = getMostRecentReattendJobName(
                customerQueryData?.jobs || [],
                jobTypeMapped
            );

            setRelatedJob(mostRecentReattendJobName);
            setReattend(mostRecentReattendJobName ? Reattend.YES : Reattend.NO);
        }
    }, [formState, customerQueryData]);

    if (isLoading) {
        return <Loader />;
    }

    if (isError || !customerQueryData) {
        // if error is missing email or postcode, something has gone wrong within the flow
        // of the app and we should redirect to properties page
        const errorMessage = customerQueryError
            ? (customerQueryError as Error).message
            : 'Something went wrong';

        if (errorMessage === EMAIL_AND_POSTCODE_REQUIRED) {
            // Landed on this page skipping a step in the flow. We should look to tighten this up more
            // but for now should be enough to navigate back to start of the flow (properties route)
            // eslint-disable-next-line no-console
            console.error(errorMessage);
            navigate(Routes.PROPERTIES);
        }

        return <ErrorBar message={errorMessage} style={styles.claimFormErrorBar} />;
    }

    if (newClaimError) {
        const errorMessage = `It seems like something went wrong. Please refresh and try again. If this message persists please call ${HOMETREE_PHONE_NUMBER}`;

        return <ErrorBar message={errorMessage} style={styles.claimFormErrorBar} />;
    }

    const trackFnolDataWrapper = async (data: FnolTrackingRequestBody) => {
        const newFnolTrackingId = await trackFnolData({
            id: fnolTrackingId || undefined,
            ...data,
        });

        if (newFnolTrackingId instanceof Error) return;

        if (fnolTrackingId !== newFnolTrackingId) {
            setFnolTrackingId(newFnolTrackingId);
        }
    };

    const onSubmit = async (submitFormState: Partial<FormState>) => {
        setIsSubmitting(true);

        const jobTypeMapped = jobTypeMap[submitFormState.jobType || ''];

        const body: Partial<FormState> = {
            ...submitFormState,
            ...customerQueryData,
            email,
            postcode: locationPostcode,
            reattend,
            relatedJob,
            userMode,
            parking: 'Yes',
            jobType: jobTypeMapped,
            ...(reattend === Reattend.YES && { visitType: 'Reattend' }),
        };

        newAnalyticsEvent({
            category: 'FNOL',
            action: 'Clicked create job',
            label: customerQueryData.customerFacingId,
            value: getUserModeId(userMode),
        });

        const createClaimResult = await createClaim(body);

        if (createClaimResult instanceof Error) {
            setNewClaimError('Error creating claim');
            return;
        }

        onUpdate({
            ...body,

            // TODO: This is how confirmation expects the data, simplify once we get rid of the old newClaim page
            skeduloJobDetails: {
                jobs: {
                    edges: [
                        {
                            node: {
                                Name: createClaimResult.jobName,
                            },
                        },
                    ],
                },
            },
        });

        await trackFnolDataWrapper({
            contractId: customerQueryData.contractId,
            skJobUid: createClaimResult.jobUid,
        });

        // Navigate to confirmation page
        navigate(Routes.CLAIM_CONFIRMATION);

        setIsSubmitting(false);
    };

    return (
        <Layout>
            <Form
                questionStages={questionStages(customerQueryData, formState, reattend)}
                initialStageHandle={INITIAL_STAGE_HANDLE}
                endStageHandles={END_STAGE_HANDLES}
                formSubmit={onSubmit}
                isFormSubmitting={isSubmitting}
                customerData={customerQueryData}
                userMode={userMode}
                trackFnolData={trackFnolDataWrapper}
                formState={formState}
                setFormState={setFormState}
            />
        </Layout>
    );
};

export default NewClaimOldAuth;
