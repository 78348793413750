import { Routes as RoutePath } from '../types/Routes';

export function shouldDisplayHeader(pathname): boolean {
    const pathsToNotShowHeader = [
        RoutePath.AUTH,
        RoutePath.PROPERTIES,
        RoutePath.DASHBOARD,
        RoutePath.LANDING,
    ];
    return !pathsToNotShowHeader.includes(pathname);
}

export const isOnAuth = (pathname): boolean => {
    return [RoutePath.AUTH, RoutePath.LANDING].includes(pathname);
};
