import * as Sentry from '@sentry/react';
import { getAttributesFromAuthenticatedUser, getAuthenticatedUser } from './authenticatedUser';

export const captureSentryError = async (
    error: Error,
    extras: Record<string, string | number | boolean>
) => {
    const user = await getAuthenticatedUser();
    const attributes = getAttributesFromAuthenticatedUser(user);

    Sentry.withScope((scope) => {
        scope.setUser({ id: attributes['custom:account_id'] });
        scope.setExtras(extras);
        Sentry.captureException(error);
    });
};

export const captureSentryInfo = async (
    message: string,
    info: {
        [key: string]: string | { [key: string]: string | number | boolean };
    } | null = null
) => {
    const user = await getAuthenticatedUser();
    const attributes = getAttributesFromAuthenticatedUser(user);

    Sentry.withScope((scope) => {
        scope.setUser({ account_id: attributes['custom:account_id'] });
        scope.setContext('INFO', info);
        Sentry.captureMessage(message);
    });
};
