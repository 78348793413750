import { JobDetails } from 'src/types/Job';
import { FormState, BoilerGaugesValue } from './types/Form';
import { Questions, QuestionType } from './types/Question';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const gasSafetyQuestions = (
    jobDetails: JobDetails,
    state: Partial<FormState>
): Questions => {
    return {
        boilerType: {
            question: 'Please select the Boiler type',
            type: QuestionType.DROPDOWN,
            options: [
                {
                    value: 'Next',
                    nextQuestion: 'boilerLocation',
                },
            ],
            onSubmit: (value) => ({ boilerType: value } as Partial<FormState>),
            defaultValue: state.boilerType || jobDetails?.sk_boiler_type || '',
            config: {
                options: [
                    { copy: 'Combi-Boiler', value: 'Combi-Boiler' },
                    { copy: 'System Boiler', value: 'System-Boiler' },
                    { copy: 'Heat Only Boiler', value: 'Heat-Only-Boiler' },
                    { copy: 'Other', value: 'Other' },
                ],
            },
            isOptional: true,
        },
        boilerLocation: {
            question: 'Please select the Boiler location',
            type: QuestionType.DROPDOWN,
            options: [{ value: 'Next', nextQuestion: 'visualInspectionSafe' }],
            onSubmit: (value) => ({ boilerLocation: value } as Partial<FormState>),
            defaultValue: state.boilerLocation || jobDetails?.sk_boiler_location || '',
            config: {
                options: [
                    { copy: 'Kitchen', value: 'kitchen' },
                    { copy: 'Bathroom', value: 'bathroom' },
                    { copy: 'Utility', value: 'utility' },
                    { copy: 'Bedroom', value: 'bedroom' },
                    { copy: 'Airing Cupboard', value: 'AiringCupboard' },
                    { copy: 'Other', value: 'Other' },
                ],
            },
            isOptional: true,
        },
        visualInspectionSafe: {
            question: 'Visual inspection of appliance in safe working order?',
            type: QuestionType.DROPDOWN,
            options: [{ value: 'Next', nextQuestion: 'safetyDeviceFsfCorrect' }],
            onSubmit: (value) => ({ visualInspectionSafe: value } as Partial<FormState>),
            config: {
                options: [
                    { copy: 'Pass', value: 'Pass' },
                    { copy: 'Fail', value: 'Fail' },
                ],
            },
            defaultValue: state.visualInspectionSafe,
        },
        safetyDeviceFsfCorrect: {
            question: 'Are all safety devices working?',
            type: QuestionType.DROPDOWN,
            options: [{ value: 'Next', nextQuestion: 'ventillationSizeConfigLabelling' }],
            onSubmit: (value) => ({ safetyDeviceFsfCorrect: value } as Partial<FormState>),
            config: {
                options: [
                    { copy: 'Yes', value: 'Yes' },
                    { copy: 'No', value: 'No' },
                ],
            },
            isOptional: true,
            defaultValue: state.safetyDeviceFsfCorrect,
        },
        ventillationSizeConfigLabelling: {
            question: 'Satisfactory ventilation on appliance?',
            type: QuestionType.DROPDOWN,
            options: [{ value: 'Next', nextQuestion: 'combustionPerformanceReading' }],
            onSubmit: (value) => ({ ventillationSizeConfigLabelling: value } as Partial<FormState>),
            config: {
                options: [
                    { copy: 'Yes', value: 'Yes' },
                    { copy: 'No', value: 'No' },
                ],
            },
            isOptional: true,
            defaultValue: state.ventillationSizeConfigLabelling,
        },
        combustionPerformanceReading: {
            question: 'Combustion Perfomance Reading',
            type: QuestionType.TEXT,
            options: [{ value: 'Next', nextQuestion: 'boilerGauges' }],
            onSubmit: (value) => ({ combustionPerformanceReading: value } as Partial<FormState>),
            isOptional: true,
            defaultValue: state.combustionPerformanceReading,
        },
        boilerGauges: {
            question: '',
            type: QuestionType.BOILER_GAUGES,
            options: [{ value: 'Next', nextQuestion: 'gasUnsafeSituation' }],
            onSubmit: (value: BoilerGaugesValue) =>
                ({
                    gasRateKw: value.gasRateKw,
                    burnerPressureMb: value.burnerPressureMb,
                } as Partial<FormState>),
            defaultValue: {
                gasRateKw: state.gasRateKw,
                burnerPressureMb: state.burnerPressureMb,
            },
        },
        gasUnsafeSituation: {
            question: 'Any unsafe situations identified? Has warning advice been issued?',
            type: QuestionType.DROPDOWN,
            options: [
                {
                    value: 'Next',
                    nextQuestionSelector: (_, formState) =>
                        typeof formState.isResolved !== 'undefined' && formState.isResolved
                            ? 'partsAvailablePhotosOfFault'
                            : 'issueArea',
                },
            ],
            onSubmit: (value) => ({ unsafeSituation: value } as Partial<FormState>),
            config: {
                options: [
                    { copy: 'N/A', value: 'NA' },
                    { copy: 'At Risk (AR)', value: 'AtRisk' },
                    {
                        copy: 'Immediately Dangerous (ID)',
                        value: 'ImmediatelyDangerous',
                    },
                ],
            },
            defaultValue: state.unsafeSituation,
        },
    };
};
