import styles from './index.module.scss';

export interface Props {
    customStyle?: string;
}

const Spinner = ({ customStyle = '' }: Props) => {
    return (
        <div className={`${styles.spinner} ${customStyle}`} data-testid="spinner-component">
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
        </div>
    );
};

export default Spinner;
