/* eslint-disable react/no-danger */
import { AttentionGradient } from 'src/images';
import Button from 'src/components/Button';
import { Config, Option } from '../../../types/Question';
import { FormState } from '../../../types/Form';
import style from './index.module.scss';

export interface Props {
    title?: string;
    body?: string;
    options: Option[];
    formState: Partial<FormState>;
    config?: Config;
    onSubmit: (value: string, nextQuestion?: string) => void;
}

const SelectQuestion = ({ title, body, options, formState, config, onSubmit }: Props) => {
    return (
        <>
            {config && config.type === 'warning' && (
                <AttentionGradient className={style.attention} />
            )}
            {title && <h3 className={style.title}>{title}</h3>}
            {body && <div className={style.body} dangerouslySetInnerHTML={{ __html: body }} />}
            <div
                className={`${style.container}  
                    ${config?.otherConfig?.className ? style[config.otherConfig.className] : ''}
                `}
            >
                {options.map(({ value, copy, nextQuestion, nextQuestionSelector }) => {
                    return (
                        <Button
                            key={value}
                            onClick={() =>
                                onSubmit(
                                    value,
                                    nextQuestionSelector
                                        ? nextQuestionSelector(value, formState)
                                        : nextQuestion
                                )
                            }
                        >
                            {copy || value}
                        </Button>
                    );
                })}
            </div>
        </>
    );
};

export default SelectQuestion;
