import { FormState } from './Form';

export enum QuestionType {
    RADIO = 'radio',
    TEXTAREA = 'textarea',
    INPUT_TEXT = 'inputText',
    DROPDOWN = 'dropdown',
    CALENDAR = 'calendar',
    PAYMENT = 'payment',
    COMPLETE = 'complete',
    FAILED = 'failed',
    TEXT = 'text',
    STAGE_LOADER = 'stageLoader',
}

export type QuestionStageValues = Record<string, string | number | boolean | string[]>;

export interface Option {
    copy: string;
    nextQuestion?:
        | string
        | ((values: QuestionStageValues, formState: Partial<FormState>) => string);
}

export interface ConfigOption {
    value: string;
    copy?: string;
    default?: boolean;
}

export interface Config {
    options?: ConfigOption[];
    extraCopy?: JSX.Element;
    otherConfig?: {
        question: string;
    };
    singleOption?: string;
    text?: {
        fieldLength: number;
    };
    placeholder?: string;
    otherTitle?: string;
}

export interface Question {
    name: string;
    description?: string;
    helper?: string;
    type: QuestionType;
    isRequired?: boolean;
    defaultValue?: Partial<FormState> | string | number | null;
    config?: Config;
    contribution?: number;
}

export interface Questions {
    [key: string]: Question;
}

export interface QuestionStage {
    handle: string;
    questions: Question[];
    onSubmit: (
        values: QuestionStageValues,
        formState: Partial<FormState>
    ) => Partial<FormState> | any;
    options?: Option[];
    config?: {
        hideOptionsButtons?: boolean;
    };
}
