/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from 'react';
import { ExpandArrow } from 'src/images';
import { motion } from 'framer-motion/dist/framer-motion';
import style from './index.module.scss';

const themes = {
    grey: `${style.themeGrey}`,
    purple: `${style.themePurple}`,
};

export type Props = {
    children: React.ReactNode;
    header?: React.ReactNode;
    main?: React.ReactNode;
    triggerSetExpanded?: boolean;
    theme?: keyof typeof themes;
};

const Expando = ({ children, header, main, triggerSetExpanded, theme = 'grey' }: Props) => {
    const [expanded, setExpanded] = useState(false);

    const animateVariants = {
        children: {
            expanded: { opacity: 1, height: 'auto' },
            closed: { opacity: 0, height: '0' },
        },
        arrow: {
            expanded: { transform: 'rotate(180deg)' },
            closed: { transform: 'rotate(0deg)' },
        },
        triangle: {
            expanded: { bottom: '-13px' },
            closed: { bottom: '10px' },
        },
    };

    useEffect(() => {
        if (triggerSetExpanded) setExpanded(triggerSetExpanded);
    }, [triggerSetExpanded]);

    return (
        <div
            className={`${style.expando} ${expanded ? `${style.expanded}` : ''}`}
            data-testid="expando-component"
            data-component="Expando"
        >
            <div className={`${style.glance} ${themes[theme]}`}>
                <div
                    className={style.header}
                    data-testid="expando-component-header"
                    data-component="Header"
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                        if (expanded) {
                            setExpanded(false);
                            return;
                        }

                        setExpanded(true);
                    }}
                >
                    {header}
                    <motion.div
                        className={style.arrow}
                        animate={expanded ? 'expanded' : 'closed'}
                        variants={animateVariants.arrow}
                    >
                        <ExpandArrow />
                    </motion.div>
                </div>

                <div>{main}</div>

                <motion.span
                    animate={expanded ? 'expanded' : 'closed'}
                    variants={animateVariants.triangle}
                    className={`${style.bottomTriangle} ${themes[theme]}`}
                />
            </div>
            <motion.div
                initial={{
                    height: 0,
                    opacity: 0,
                }}
                animate={expanded ? 'expanded' : 'closed'}
                className={style.details}
                variants={animateVariants.children}
            >
                <div>{children}</div>
            </motion.div>
        </div>
    );
};

export default Expando;
