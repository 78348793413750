import { PandoraErrorResponse, pandoraErrorResponseSchema } from 'src/types/pandora';
import { JSONSchemaType } from 'ajv';

export interface Account {
    email: string;
    first_name: string;
    last_name: string;
    phone1: string;
    phone1_prefix: string;
    phone2: string;
    phone2_prefix: string;
}

export interface GetAccountSuccessResponse {
    data: Account[];
}

export type GetAccountResponse = GetAccountSuccessResponse | PandoraErrorResponse;

export const accountSchema: JSONSchemaType<Account> = {
    type: 'object',
    properties: {
        email: { type: 'string' },
        first_name: { type: 'string' },
        last_name: { type: 'string' },
        phone1: { type: 'string' },
        phone1_prefix: { type: 'string' },
        phone2: { type: 'string' },
        phone2_prefix: { type: 'string' },
    },
    required: [
        'email',
        'first_name',
        'last_name',
        'phone1',
        'phone1_prefix',
        'phone2',
        'phone2_prefix',
    ],
};

export const getAccountSuccessResponseSchema: JSONSchemaType<GetAccountSuccessResponse> = {
    type: 'object',
    properties: {
        data: { type: 'array', items: accountSchema },
    },
    required: ['data'],
};

export const getAccountResponseSchema: JSONSchemaType<GetAccountResponse> = {
    type: 'object',
    anyOf: [getAccountSuccessResponseSchema, pandoraErrorResponseSchema],
    additionalProperties: true,
};
