import { useEffect, useState } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Toaster } from 'react-hot-toast';
import AuthForm from './components/AuthForm';
import CookieBanner from './features/cookieBanner';
import Confirmation from './components/Confirmation';
import NavBar from './features/navBar';
import ProtectedRoute from './components/ProtectedRoute';
import { CustomerProvider } from './contexts/Customer';
import { Login } from './features/login';
import NotFound from './features/notFound';
import { Properties } from './features/properties';
import { newAnalyticsEvent } from './utils/analytics';
import { toggleNewLogin } from './utils/toggleNewLogin';
import { isOnAuth, shouldDisplayHeader } from './utils/displayHeader';
import { getUserModeId, getUserModeFromUrl, getClaimModeFromUrl } from './utils/userMode';
import { Routes as RoutePath } from './types/Routes';
import { CloseJob } from './features/closeJob';
import { RescheduleJob } from './features/rescheduleJob';
import { JobOffers } from './features/jobOffers';
import { Role } from './types/Role';
import { Landing } from './features/landing';
import { NotImplemented } from './features/notImplemented';
import { getMandatoryEnvVar } from './utils/env';
import { JobAgendas } from './features/jobAgenda';
import { CustomerAccount } from './features/account/customer/profile';
import { Profile as EngineerProfile } from './features/account/engineer/profile';
import { EngineerAccount } from './features/account/engineer/account';
import { Invoices as EngineerInvoices } from './features/account/engineer/invoices';
import style from './index.module.scss';
import { JobDetailsRoute } from './features/jobDetails';
import { JobOfferDetails } from './features/jobOfferDetails';
import LocationDemo from './features/locationDemo';
import {
    NewClaim as NewClaimWip,
    NewClaimOldAuth as NewClaimWipOldAuth,
} from './features/newClaimWip';
import RebrandHeader from './features/login/components/rebrandHeader';
import { Dashboard } from './features/dashboard';
// import TermsAndConditions from './features/termsAndConditions/components';
// import DocumentsModal from './features/documentsModal';
// import { isNotOnDocumentsPage, justSignedDocs } from './utils/displayDocumentsModal';
// import { useGetDocumentsSignRequired } from './features/termsAndConditions/hooks/useGetDocumentsRequired';

const publicStripeKey = getMandatoryEnvVar('REACT_APP_PUBLIC_STRIPE_KEY');
const stripePromise = loadStripe(publicStripeKey);

export type State = {
    paramUserMode: string;
    paramClaimMode: string;
    documentsSignRequired?: boolean;
    justSignedDocsOn?: string;
};

const App = () => {
    const [state, setState] = useState<State>({
        paramUserMode: getUserModeFromUrl(window.location.search),
        paramClaimMode: getClaimModeFromUrl(window.location.search),
    });
    const [showBanner, setShowBanner] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();

    // const { isError, isLoading, data } = useGetDocumentsSignRequired('documentsSignRequired');

    useEffect(() => {
        const { paramUserMode } = state;
        newAnalyticsEvent({
            category: 'Authentication',
            action: 'Landed',
            value: getUserModeId(paramUserMode),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Ensure we're at the top of the page on url change
        // We tried to test this but ran into issues
        window.scrollTo(0, 0);
        // Required to handle cases when user inputs url directly. We need S3 to handle this with redirect rules as there is no file at that url
        // need to clean url up in app as a result
        // Reference: https://via.studio/journal/hosting-a-reactjs-app-with-routing-on-aws-s3
        const mapS3RedirectToRoute = () => {
            const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
            if (path) {
                navigate(path);
            }
        };
        mapS3RedirectToRoute();
    }, [location, navigate]);

    const handleClaimChange = (claimState) => {
        setState((prevState) => ({
            ...prevState,
            ...claimState,
        }));
    };

    // Tempory until new login page is deployed
    const oldAuthRoutes = (
        <>
            <Route path={RoutePath.AUTH} element={<AuthForm value={state} />} />
            <Route
                path={RoutePath.CLAIM_NEW}
                element={
                    <Elements stripe={stripePromise}>
                        <NewClaimWipOldAuth
                            onUpdate={(claimState) => handleClaimChange(claimState)}
                            userMode={state.paramUserMode}
                        />
                    </Elements>
                }
            />
            <Route path={RoutePath.CLAIM_CONFIRMATION} element={<Confirmation value={state} />} />
            <Route path={RoutePath.LANDING} element={<AuthForm value={state} />} />
            <Route path={RoutePath.ANYTHING_ELSE} element={<NotFound />} />
            <Route
                path={RoutePath.CLAIM_NEW_IN_PROGRESS}
                element={
                    <NewClaimWipOldAuth
                        onUpdate={(claimState) => handleClaimChange(claimState)}
                        userMode={state.paramUserMode}
                    />
                }
            />
        </>
    );
    const newAuthRoutes = (
        <>
            <Route path={RoutePath.AUTH} element={<Login />} />
            <Route
                path={RoutePath.CLAIM_NEW}
                element={
                    <Elements stripe={stripePromise}>
                        <ProtectedRoute roles={[Role.CUSTOMER]}>
                            <NewClaimWip
                                onUpdate={(claimState) => handleClaimChange(claimState)}
                                userMode={state.paramUserMode}
                            />
                        </ProtectedRoute>
                    </Elements>
                }
            />
            <Route
                path={RoutePath.CUSTOMER_ACCOUNT}
                element={
                    <ProtectedRoute roles={[Role.CUSTOMER]}>
                        <CustomerAccount />
                    </ProtectedRoute>
                }
            />
            <Route
                path={RoutePath.ENGINEER_ACCOUNT}
                element={
                    <ProtectedRoute roles={[Role.ENGINEER]}>
                        <EngineerAccount />
                    </ProtectedRoute>
                }
            />
            {/* <Route
                path={RoutePath.TERMS_AND_CONDITIONS}
                element={
                    <ProtectedRoute roles={[Role.ENGINEER]}>
                        <TermsAndConditions setState={setState} documents={data || []} />
                    </ProtectedRoute>
                }
            /> */}
            <Route
                path={RoutePath.ENGINEER_ACCOUNT_PROFILE}
                element={
                    <ProtectedRoute roles={[Role.ENGINEER]}>
                        <EngineerProfile />
                    </ProtectedRoute>
                }
            />
            <Route
                path={RoutePath.ENGINEER_ACCOUNT_INVOICES}
                element={
                    <ProtectedRoute roles={[Role.ENGINEER]}>
                        <EngineerInvoices />
                    </ProtectedRoute>
                }
            />
            <Route path={RoutePath.CLAIM_CONFIRMATION} element={<Confirmation value={state} />} />
            <Route
                path={RoutePath.PROPERTIES}
                element={
                    <ProtectedRoute roles={[Role.CUSTOMER]}>
                        <Properties />
                    </ProtectedRoute>
                }
            />
            <Route
                path={RoutePath.DASHBOARD}
                element={
                    <ProtectedRoute roles={[Role.CUSTOMER]}>
                        <Dashboard />
                    </ProtectedRoute>
                }
            />
            <Route
                path={RoutePath.LANDING}
                element={
                    <ProtectedRoute>
                        <Landing />
                    </ProtectedRoute>
                }
            />
            <Route
                path={RoutePath.CLOSE_JOB}
                element={
                    <ProtectedRoute roles={[Role.ENGINEER]}>
                        <CloseJob />
                    </ProtectedRoute>
                }
            />
            <Route
                path={RoutePath.RESCHEDULE_JOB}
                element={
                    <ProtectedRoute roles={[Role.ENGINEER]}>
                        <RescheduleJob />
                    </ProtectedRoute>
                }
            />
            <Route
                path={RoutePath.JOB_OFFERS}
                element={
                    <ProtectedRoute roles={[Role.ENGINEER]}>
                        <JobOffers />
                    </ProtectedRoute>
                }
            />
            <Route
                path={RoutePath.JOB_OFFER_DETAILS}
                element={
                    <ProtectedRoute roles={[Role.ENGINEER]}>
                        <JobOfferDetails />
                    </ProtectedRoute>
                }
            />
            <Route
                path={RoutePath.JOB_AGENDA}
                element={
                    <ProtectedRoute roles={[Role.ENGINEER]}>
                        <JobAgendas />
                    </ProtectedRoute>
                }
            />
            <Route
                path={RoutePath.JOB_DETAILS}
                element={
                    <ProtectedRoute roles={[Role.ENGINEER]}>
                        <JobDetailsRoute />
                    </ProtectedRoute>
                }
            />
            <Route path={RoutePath.ANYTHING_ELSE} element={<NotFound />} />
            <Route
                path={RoutePath.ENGINEER_NOT_IMPLEMENTED}
                element={
                    <ProtectedRoute roles={[Role.ENGINEER]}>
                        <NotImplemented content="Please use the link in the text message to create the follow on visit" />
                    </ProtectedRoute>
                }
            />
            <Route
                path={RoutePath.ANYTHING_ELSE}
                element={
                    <ProtectedRoute>
                        <NotFound />
                    </ProtectedRoute>
                }
            />
            <Route path={RoutePath.LOCATION_DEMO} element={<LocationDemo />} />
        </>
    );
    const routes = toggleNewLogin(state.paramUserMode) ? newAuthRoutes : oldAuthRoutes;

    return (
        <div className={isOnAuth(location.pathname) ? style.rebrandBody : ''}>
            <CustomerProvider>
                {isOnAuth(location.pathname) ? <RebrandHeader /> : <RebrandHeader version="dark" />}
                {shouldDisplayHeader(location.pathname) && <NavBar />}
                <main className={`${showBanner ? style.withCookieBanner : ''}`}>
                    <Routes>{routes}</Routes>
                </main>
                {showBanner && (
                    <CookieBanner
                        setShowBanner={setShowBanner}
                        isAuthPage={!shouldDisplayHeader(location.pathname)}
                    />
                )}
                {/* {!isLoading &&
                    !isError &&
                    (data || []).length > 0 &&
                    isNotOnDocumentsPage(location.pathname) &&
                    !justSignedDocs(state.justSignedDocsOn) && (
                        <DocumentsModal documents={data || []} />
                    )} */}
            </CustomerProvider>
            <Toaster />
        </div>
    );
};

export default App;
