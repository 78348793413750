import React, { useEffect } from 'react';
import { isDayBookable } from 'src/utils/dateForm';
import { JobUrgency } from 'src/types/JobUrgency';
import DateRadio from 'src/components/Calendar/DateRadio';

const determineDaysToAdd = (date, urgency: JobUrgency = JobUrgency.NORMAL) => {
    if (urgency === JobUrgency.CRITICAL || urgency === JobUrgency.URGENT) {
        if (date.getHours() >= 13) {
            return 1;
        }

        return 0;
    }

    let daysToAdd = 3;
    switch (date.getDay()) {
        case 4:
        case 5:
        case 6:
            daysToAdd = 4;
            break;
        default:
            break;
    }

    return daysToAdd;
};

const generateDates = (urgency: JobUrgency = JobUrgency.NORMAL, alreadySelectedDates: Date[]) => {
    const weekArray = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const addInitialZero = (number) => {
        if (number < 10) {
            return `0${number}`;
        }

        return `${number}`;
    };

    const dateToValue = (date) => {
        const dateDay = addInitialZero(date.getDate());
        const dateMonth = addInitialZero(date.getMonth() + 1);
        const dateYear = date.getFullYear();

        return `${dateYear}-${dateMonth}-${dateDay}`;
    };

    const daysToAdd = determineDaysToAdd(new Date(Date.now()), urgency);

    const daysShown = 30;

    return [...Array(daysShown).keys()]
        .map((numberOfDays) => numberOfDays + daysToAdd)
        .map((numberOfDays) => {
            const date = new Date(Date.now());
            date.setDate(date.getDate() + numberOfDays);
            return date;
        })
        .map((date) => ({
            available: isDayBookable(date, urgency, alreadySelectedDates),
            dateNum: date.getDate(),
            dateWeek: weekArray[date.getDay()],
            value: dateToValue(date),
        }));
};

type Props = {
    alreadySelectedDates: Date[];
    errors: Record<string, string>;
    name: string;
    onChange: (e: React.FormEvent<HTMLInputElement>) => void;
    selectedDate?: string;
    subtitle?: string;
    title: string | React.ReactNode | Element;
    urgency?: JobUrgency;
};

const DateForm = ({
    alreadySelectedDates,
    errors,
    name,
    onChange,
    selectedDate,
    subtitle,
    title,
    urgency,
}: Props) => {
    useEffect(() => {
        // Whenever there's a change affecting what dates
        // are available, reset the scroll to far left
        document.querySelector(`#${name} > .dateradioform`)!.scrollLeft = 0;
    }, [name, urgency]);

    return (
        <div id={name} className="claimform-inputblock" data-question-name={name}>
            {title && (
                <h2>
                    {title} <span className="claimform-red">*</span>
                </h2>
            )}
            {subtitle ? <h3>{subtitle}</h3> : ''}
            <div className="dateradioform">
                {generateDates(urgency, alreadySelectedDates).map((dateData) => {
                    const isChecked = selectedDate === dateData.value;
                    return (
                        <DateRadio
                            key={`${name}-${dateData.value}`}
                            name={name}
                            value={dateData.value}
                            disabled={!dateData.available}
                            onChange={onChange}
                            isChecked={isChecked}
                            dateWeek={dateData.dateWeek}
                            dateNum={dateData.dateNum}
                        />
                    );
                })}
            </div>
            {errors[name] ? <div className="fnolform-error">{errors[name]}</div> : false}
        </div>
    );
};

export { DateForm as default, determineDaysToAdd, generateDates };
