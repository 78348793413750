import { JobUrgency } from 'src/types/JobUrgency';

export interface FormState {
    contribution: number;
    vulnerable: string;
    boilerMaker: string;
    boilerModel: string;
    boilerFaultCode: string;
    problemType: string;
    reattend: string;
    boilerAge: string;
    parking: string;
    firstName: string;
    lastName: string;
    billingFullName: string | null;
    customerFacingId: string;
    jobType: string;
    dayOneDate: string;
    dayOneTime: string[];
    dayTwoDate: string;
    dayTwoTime: string[];
    dayThreeDate: string;
    dayThreeTime: string[];
    email: string;
    postcode: string;
    description: string;
    paymentId: string | null;
    contractId: number;
    userMode: string;
    visitType: string | null;
    relatedJob: string | null;
    stripe_amount: number | null;
    sk_account_id: string;
    urgency: JobUrgency;
    gasLeak: string | null;
    gasHeat: string | null;
    gasMains: string | null;
    boilerIssue: string | null;
    electricalFailure?: string | null;
    electricIssue?: string | null;
    electricOutdoorAbove3m?: string | null;
    rejectProduct?: string;
    plumbingIssue?: string | null;
    heatingProblemRelateTo?: string | null;
    heatingLeakVisibility?: string | null;
    plumbingToiletType?: string | null;
    plumbingToiletLeak?: string | null;
    plumbingToiletFlushing?: string | null;
    plumbingToiletBlockage?: string | null;
    drainsProblemRelateTo?: string | null;
    affectedWindowInMainHouse?: string | null;
    externalWindow?: string | null;
    insecureProperty?: string | null;
    affectedLockInMainHouse?: string | null;
    lockedOutFromProperty?: string | null;
    lockOnExternalWindowOrDoor?: string | null;
    doorsIssueArea?: string | null;
    doorsTypeOf?: string | null;
    doorsElectric?: string | null;
    doorsSecureBuilding?: string | null;
    pestsType?: string | null;
    pestsMiceInsideHome?: string | null;
    pestsWaspsInsideProperty?: string | null;
}

export enum Reattend {
    YES = 'Yes',
    NO = 'No',
}
